import { useCallback } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Cancel } from '@mui/icons-material'
import { Chip, Stack } from '@mui/material'
import type { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { useAnalyticsTracking } from 'src/hooks/analytics'
import { inferLanguageLabelByLanguageCode } from 'src/utils/plain/inferLanguageLabelByLanguageCode'

import { useSearchContext } from '@/contexts/SearchContext/SearchContext'
import { getLanguageAndCountry } from '@/utils'

const dataCy = 'ActiveFiltersChips'

const ChipItem = ({
  item,
  handleClick,
}: {
  item: CurrentRefinementsConnectorParamsRefinement
  handleClick(item: CurrentRefinementsConnectorParamsRefinement): void
}) => {
  const { setInternalQuery } = useSearchContext()
  const { trackFilterEvent } = useAnalyticsTracking()

  const { t: tDA } = useTranslation('dam-assets')

  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)

  const refinement = {
    label: item.label,
    attribute: item.attribute,
    value: item.value,
    type: item.type,
  }

  let label = item.value
  if (item.attribute === 'language') {
    label = inferLanguageLabelByLanguageCode(languageCode)(String(label))
  } else if (item.attribute === 'division') {
    label = tDA(`divisions.${label}`)
  } else if (item.attribute === 'documentTypes') {
    label = tDA(`assetCategories.${label}`)
  }

  const onDelete = () => {
    if (item.attribute === 'query') return setInternalQuery('')
    handleClick(refinement)
    trackFilterEvent(item.attribute, String(item.value))
  }

  return (
    <Chip
      label={label}
      variant="outlined"
      onDelete={onDelete}
      data-cy={`${dataCy}-chip-${item.attribute}-${item.value}`}
      deleteIcon={
        <Cancel data-cy={`${dataCy}-chip-remove-${item.attribute}-${item.value}-filter`} />
      }
    />
  )
}

// TODO too complex, simplify it
export const ActiveFiltersChips = () => {
  const { items, refine } = useCurrentRefinements()

  const handleClick = useCallback(
    (refinement: CurrentRefinementsConnectorParamsRefinement) => {
      refine(refinement)
    },
    [refine],
  )

  if (items.length === 0) return null

  return (
    <Stack direction="row" sx={{ gap: 2, flexWrap: 'wrap' }}>
      {items.map((item) =>
        item.refinements.map((nested) => (
          <ChipItem
            key={`${nested.attribute}-${nested.value}`}
            item={nested}
            handleClick={handleClick}
          />
        )),
      )}
    </Stack>
  )
}
